import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { AddServiceSectionTitle, InputTextAdd, LabelAdd } from '../../components/Bloc';
import { KwafPack } from '../../components/vps/Packages';
import UserMgt from '../../lib/user_managment';
import Utils from '../../utils';
import  DeployServerWrapper from '../DeployServerWrapper';

export default function KwafNew(props) {
    const {isTrial, isPromo} = props
    const {t} = useTranslation()
    
    const { vps_packages } = useSelector(app => app.vps)
    const [hoverServerName, setHoverToolTopServerName] = useState(false)
    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [packSelected, setPack] = useState(null)
    const [codeApplied, setCodeApplied] = useState(null)
    const navigate = useNavigate()

    const [vps_params, setVpsParams] = useState({
        server_name: '',
        image: "K-WAF",
        // email: "",
        location: null,
        trialCode: null,
        promoCode: null,
        isWithPromo: isPromo,
        isAdminFreeDeployment: null
    })

 
    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)

    

    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }


    async function getVpsTypes() {
        if(!vps_packages) {
            const r =  await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        } 
            
        return vps_packages
    }
 


    useEffect(() => {
        getVpsTypes().then(vps_packages => {
            if(Utils.getUrlParam('prefer')){
                const selectedIdVpsTypes = Utils.getUrlParam('prefer')
                const pack = vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes))
                if(pack){
                    setClicked(parseInt(selectedIdVpsTypes));
                    onClaculFlavor(vps_packages.find(vps => vps.Id === parseInt(selectedIdVpsTypes)))

                }
                window.localStorage.removeItem('@user_first_service')
            }
        })
    }, [])
 
    async function functionFinish(){
        await UserMgt.get_user_vps()
        navigate('/security/kwaf')
    }

    function validate(){
        if (!vps_params.vpsTypeId) {
            return {
                isFail: true,
                message: "Package not selected"
            }
        }
        if (!vps_params.location) {
            return {
                isFail: true,
                message: "Service location not selected"
            }
        }

        if (isPromo && !codeApplied) {
            return {
                isFail: true,
                message: "You should apply promo code !"
            }
        }
        return {
            isFail: false
        }
    }
 

    function onClaculFlavor(pack) {
        if(pack) setPack({...pack})
        else setPack(null)
        setVpsParams({ 
            ...vps_params,  
            vpsTypeId: pack?.Id,  
            plan_id: (isTrial? pack?.PaypalPackIdFree : pack?.PaypalPackId) 
        })
    }




    //PaypalPackId
    const concertPack = vps_packages &&  
                        vps_packages.filter(p => p.service?.task_name === "kwaf")
                        .sort((a, b) => parseInt(a.Price ) -  parseInt(b.Price))
    return (
        <DeployServerWrapper
            paypal={paypal}
            packSelected={packSelected}
            isTrial={isTrial}
            isPromo={isPromo}
            vps_params={vps_params}
            setVpsParams={setVpsParams}
            creating={creating}
            concertPack={concertPack}
            handleChange={handleChange}
            isSecurity

            codeApplied={codeApplied}
            codeApplying={null}
            type={"K-WAF"}
            functionFinish={functionFinish}
            setPaypal={setPaypal}
            validate={validate}
            setCreating={setCreating}
            setCodeApplied={setCodeApplied}
            setCodeApplying={()=>{}}
        >   
            <div>
                <div className=" 2xl:gap-10 md:gap-6 gap-3 mt-5 pb-5 border-b-2 dark:border-gray-800 flex flex-wrap  justify-center ">
                    <KwafPack
                        concertPack={concertPack} 
                        setSelected={setSelected} 
                        creating={creating} 
                        clicked={clicked} 
                        setClicked={setClicked} 
                        onClaculFlavor={onClaculFlavor} 
                        setPackPaypal={()=>{}} 
                        selected={selected} 
                    />
                </div>

            
                <AddServiceSectionTitle
                    title="K-WAF Informations"
                />

                <div className={'w-full md:w-5/6 xl:w-[75%] 2xl:w-[70%]  mx-auto pt-10 pb-[15px]'}>
                    <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                        <LabelAdd
                            htmlFor="serv-name"
                            placeholder={"Identify your K-WAF instance"}
                        />
                        <Tooltip open={hoverServerName}   title={t("deploy.serverNameTooltip2")}>
                            <InputTextAdd
                                idx="serv-name"
                                value={vps_params.server_name}
                                name="server_name"
                                // pattern={pattern || "[A-Za-z0-9_-]{2,}"} 
                                onChange={handleChange}
                                required
                                hover
                                onHover={(state) => setHoverToolTopServerName(state)}
                                disabled={creating}
                                placeholder={t("deploy.identfyInstancePlaceholder")}
                                onInput={function(e) {
                                    const input = e.target;
                                    if(!Utils.isValidateServiceName(input.value)){
                                        input.setCustomValidity(t("deploy.errorServiceName", {serviceName: input.value}));
                                    }else{
                                        input.setCustomValidity("");
                                    }
                                }}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div> 
        </DeployServerWrapper>
    );
}



