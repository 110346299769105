import { DeleteFilled, EditFilled, LoadingOutlined, MinusOutlined, PlusOutlined, QuestionOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Popconfirm, Tooltip } from 'antd';
import { Select } from 'antd';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { BsFillLightbulbFill } from "react-icons/bs";
import { FaLeanpub } from "react-icons/fa";
import { useSelector } from "react-redux";

import { Texto } from 'layouts/TextoLayout';

import truc2 from "../assets/img/bg/truckbody-2.svg"
import mars1 from "../assets/img/bg/truckwheel-2.svg"
import Utils from '../utils';
import { Loader } from './OkButton';

export function PageTitle({text}){
    return(
        <span className="font-bold text-primary text-xl 2xl:text-2xl">
            {text}
        </span>
    )
}
export function NetworkRender({vpc, OpenstackInstanceExtIp}){
    if(!vpc?.length) return "(Not enabled)"
    else{
        return(
            <>
                {OpenstackInstanceExtIp ? " - ": ""}
                {vpc?.map((vpc, i) => {
                    return <Tooltip 
                        title={
                            vpc?.isDefault ?
                            "Default VPC, "+"subnet: "+vpc?.network?.subnet
                            :
                            "Name: "+vpc?.network?.name +", subnet: "+vpc?.network?.subnet
                        }  
                        key={i}
                    >{
                        (i !==0 ? ", " : "")+vpc.privateIp
                    }</Tooltip>
                })}
            </> 
        )
    }
}

export function ExtLink({href, text, className, icon, onclick, isLean, noHover}){
    const {t} = useTranslation()
    if(isLean){
        return (
            <a href={href !== "#" && href !== "javascript:;" && href ? href : null} className="flex cursor-pointer items-center font-medium gap-1  ">
                <span className="text-sm 2xl:text-base">{t('learn')}</span> 
                <FaLeanpub className="text-xl 2xl:text-3xl"/>
            </a>
        )
    }
    return (
        <a 
            target="_blank" 
            href={href !== "#" && href !== "javascript:;" && href ? href : null} 
            className={` ${!noHover &&"extlink"} ${className} hover:underline text-sm 2xl:text-base cursor-pointer   `}
            onClick={typeof onclick === "function" ? onclick() : undefined}
        >
            {icon}
            {text}
        </a>
    )
}

export function DeleteLineButton({onDelete, record, placeholder, tooltip}){
    return(
        <Popconfirm
            placement="top"
            title={"Warning"}
            description={`Do you realy want to delete this ${placeholder} `}
            okText="Yes, I do"
            onConfirm={() => onDelete(record)}
            cancelText="No, I don't"
        >
            <Tooltip    
                title={tooltip || `Delete ${placeholder}`} 
            >
                <Button 
                    className="hover:text-red-500" 
                    danger
                    icon={<DeleteFilled />} 
                />
            </Tooltip>
        </Popconfirm>
    )
}

export function EditLineButton({onFunction, placeholder}){
    return(
        <Tooltip    
            title={`Edit ${placeholder}`} 
        >
            <Button 
                className='border-primary'
                onClick={() => onFunction()}
                icon={<EditFilled />} 
            />
        </Tooltip>
    )
}
export function RemoveLineButton({onFunction, placeholder, className, outlined}){
    return(
        <Tooltip    
            title={`${placeholder}`} 
        >
            <Button
                danger
                className={` ${className} ${!outlined ? "bg-red-500 text-white" : ""} `} 
                icon={<MinusOutlined  className={` ${outlined ? "text-red-500" : " text-white "} `}   />}
                onClick={()=>{
                    onFunction()
                }}
            />
        </Tooltip>
    )
}

export function AddLineButton({onFunction, placeholder, outlined}){
    return(
        <Tooltip    
            title={` ${placeholder}`} 
        >
            <Button 
                {...{type: !outlined ? "primary" : null}}
                className='border-primary'
                onClick={() => onFunction()}
                icon={<PlusOutlined />} 
            />
        </Tooltip>
    )
}
export function Spiner({fontSize, customClass}){ 
    return(
        <Spin className={customClass? customClass : ""} indicator={<LoadingOutlined style={{ fontSize: fontSize || 24 }} spin />} />
    )
}
export function ListAppWrapper(props){
    return( 
        <>
            <div className='mars  '>
                <input  id='index1' className="invisible" name='trigger' defaultChecked={props.check?.index1} type='radio'/>
                <input id='index2' defaultChecked={props.check?.index2}name='trigger' className="invisible
                " type='radio'/>
                <input id='index3' defaultChecked={props.check?.index3}name='trigger'  className="invisible
                " type='radio'/>
                <input id='index4' defaultChecked={props.check?.index4}name='trigger'  className="invisible
                " type='radio'/>
                <input id='index5' defaultChecked={props.check?.index5}name='trigger'  className="invisible
                " type='radio'/>
                <input id='index6' defaultChecked={props.check?.index6} name='trigger'  className="invisible" type='radio'/>
                <div className='mars_truck'>
                    <div className='mars_truck__wheel'>
                      <img src={mars1}/>
                    </div>
                    <div className='mars_truck__wheel--two'>
                      <img src={mars1}/>
                    </div>
                    <div className='mars_truck__body'>
                      <img src={truc2} />
                    </div>
                </div>
                <div className='mars_buildings'></div>
                <div className='mars_parallax'></div>
                <div className='mars_parallax--two'></div>
            </div>
           {props.children}
        </>
    )
}

export function HelpIcon({message, className}){
    return(
        <Tooltip 
            title={message}
            className={className}
        >
            <Button size='small' type="default" shape="circle" icon={<QuestionOutlined />} />
        </Tooltip>
    )
}
export function DeleteServiceTap({server, onDelete, canotDelete}){
    const { t } = useTranslation(); 
    return(
        <div className="">
            <div className="flex gap-2 items-center">
                <PageTitle text={t("deleteCompoent.h1")} />
            </div>
            <Texto small className=" pt-2">
                {t("deleteCompoent.text1")}
            </Texto>
            <Texto small className=" pt-2">
                {t("deleteCompoent.text2")}
            </Texto>
            <br />
            <br />
            <Tooltip 
                title={canotDelete? t('deleteCompoent.cannotDeleteServiceText') : null}
            >
                <Button  
                    size="large" 
                    onClick={()=> !canotDelete && onDelete()}
                    className="bg-red-500 rounded-full" 
                    // disabled={canotDelete}
                    icon={<DeleteFilled />}
                    danger
                    style={{color: "white"}}
                >
                    {t('deleteCompoent.button')}
                </Button>
            </Tooltip>  
        </div>
    )
}
export function Block(){
    return(
        <div className=" pb-32 px-3 md:px-8 h-auto">
            {/* <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
                </div>
            </div> */}
        </div>
    )
}

export function ServiceSubmitButton({title, creating}){
    return(
        <button className="flex mt-4 mx-auto text-xl   w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
            type="submit"
            disabled={creating}
        >
                {!creating ?
                    <span className='mx-auto flex  items-center'>
                        <BsFillLightbulbFill className='text-2xl' />
                        {" Upgrade now"}
                    </span>
                    :
                    <span className='mx-auto flex  items-center'>
                        <Loader white />
                    </span>
                }
        </button>
    )
}
export function InputText({placeholder, value, idx, name, disabled, onChange, pattern, required, className}){
    return(
        <Input 
            required={required}
            onChange={onChange} 
            pattern={pattern} 
            placeholder={placeholder} 
            value={value} 
            id={idx}  
            name={name} 
            disabled={disabled} 
            className={className}
        />
    )
}

export function InputPassword({placeholder, status, value, idx, name, disabled, onChange, pattern, required}){
    // const [passwordVisible, setPasswordVisible] = React.useState(false);
    return(
        <Input.Password 
            status={status}  
            required={required} 
            onChange={onChange} 
            pattern={pattern} 
            placeholder={placeholder} 
            value={value} 
            id={idx} 
            name={name} 
            disabled={disabled} 
        />
    )
}

export function InputSelect({value, placeholder, options, disabled, onChange, name}){
    const onChange2 = (v) => {
        onChange(name, v)
      };


    return(
        <Select
            // showSearch
            placeholder={placeholder}
            // popupClassName='bg-neutral-100'
            // optionFilterProp="children"
            onChange={onChange2}
            // onSearch={onSearch}
            //filterOption={filterOption}
            disabled={disabled}
            options={options}
            value={value}
        />
    )
}


export function InputTextAdd({placeholder, type, onHover, value, hover, idx, name, disabled, onChange, pattern, required, onInput}){
    return(
        <input 
            onMouseLeave={() => hover ? onHover(false) : ""} 
            onMouseOver={() => hover ? onHover(true) : ""}
            required={required}
            onChange={onChange} 
            pattern={pattern} 
            placeholder={placeholder} 
            value={value} 
            id={idx}  
            name={name} 
            disabled={disabled} 
            type={type}
            onInput={onInput}
            className="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border
                rounded
                py-2
                2xl:py-3
                px-4
                //mb-3
                leading-tight
                dark:bg-bgPagedark
                dark:text-darkTextColor
                focus:outline-none focus:bg-white
            "
        />
    )
}
export function InputPassAdd(
    {
        placeholder, onHover, value, hover, idx, name, disabled, onChange, pattern, required
    }
){
    const [visiblePass, setVissiblePass] = useState(false)
    const [passwordTooltip, setDisplayPassToolTip] = useState(false)

    function changedLocal(ev){
        const { name, value } = ev.target
        const shoudTooltip = (String(value).trim()).length < 6 || value.includes(' ')
        setDisplayPassToolTip(shoudTooltip)
        onChange(ev)
    }
    return(
        <Tooltip 
            open={passwordTooltip}   
            title={
                "The password must be at least 6 characters and should not includes empty space"+
                (
                    pattern ? `, the special characters :/?#&="<>{}|\^%[]()'\`;,+` : ""
                )
            }
        >
            <div className="flex h-9 2xl:h-12 mb-3">
                <input 
                    onMouseLeave={() => hover ? onHover(false) : ""} 
                    onMouseOver={() => hover ? onHover(true) : ""}
                    required={required}
                    onChange={changedLocal}
                    onInput={function(e) {
                        const input = e.target;
                        const value = input?.value;
                        if(value && value?.length < 6){
                            return input.setCustomValidity(
                                "The password must be at least 6 characters and should not includes empty space"+
                                (
                                    pattern ? `, the special characters :/?#&="<>{}|\^%[]()'\`;,+` : ""
                                )
                            );
                        }
                        if(pattern){
                            const reg = /^[^:/?#&="<>{}|\\^%[\]+()'`;,\s]*$/
                            if(!reg.test(value)){
                                input.setCustomValidity(`The special characters :/?#&="<>{}|\^%[]()'\`;,+ are not allowed`);
                            }else{
                                input.setCustomValidity("");
                            }
                        }else{
                           return input.setCustomValidity("") 
                        }
                        
                    }}
                    placeholder={placeholder}  
                    value={value} 
                    id={idx}  
                    name={name} 
                    disabled={disabled} 
                    type={!visiblePass && "password"}
                    className="
                        appearance-none
                        block
                        w-full
                        bg-gray-200
                        text-gray-700
                        border
                        rounded
                        py-2
                        2xl:py-3
                        px-4
                        //mb-3
                        h-9
                        2xl:h-12
                        leading-tight
                        dark:bg-bgPagedark
                        dark:text-darkTextColor
                        focus:outline-none focus:bg-white
                    "
                />
                <div onClick={() => setVissiblePass(!visiblePass)} className="flex px-4 cursor-pointer bg-gray-200 dark:bg-bgPagedark items-center justify-center  border">
                    {visiblePass ? <AiFillEyeInvisible className="text-2xl text-primary dark:text-darkTextColor" /> : <AiFillEye className="text-2xl text-primary dark:text-darkTextColor" />}
                </div>
            </div>
        </Tooltip>
    )
}
export function LabelAdd({placeholder, htmlFor, toLowerCase}){
    return(
        <label
            className={`
                    block  
                    tracking-wide 
                    text-gray-700 text-xs 
                    dark:text-darkTextColor 
                    font-bold 
                    mb-2 
                    uppercase
                    `
               
            }
            htmlFor={htmlFor}
        >
            {placeholder}
        </label>
    )
}

export function AddServiceSectionTitle({title, desc}){
    return(
        <p className='text-center 2xl:text-2xl xl:text-xl text-base font-bold text-primary dark:text-darkTextColor pt-7'>
            {title}
            {desc ?
                <span className="text-xs lg:text-sm">
                    <br/>
                    {desc}
                </span>
                :
                null
            }
        </p>
    )
}
export function DeployAdAdmin({setVpsParams, creating, vps_params}){
    const { dontDisplayAllItems } = useSelector(app => app.core)

    useEffect(()=>{
        if(Utils.isAdmin()){
            setVpsParams({...vps_params, isAdminFreeDeployment: dontDisplayAllItems})
        }
    }, [dontDisplayAllItems])
    if(Utils.isAdmin() && !dontDisplayAllItems){
        return(
            <div className="flex justify-center">
                <Checkbox 
                    className="mx-auto font-bold"
                    disabled={creating} 
                    onChange={(e) =>setVpsParams({...vps_params, isAdminFreeDeployment: e.target.checked}) }>
                    Deploy as admin
                </Checkbox>
            </div>
        )
    }
    return <div />
}

export const CheckSVG = () =>  (
    <svg className="div_check checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
        <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
)

export const DeployButton = ({label, creating}) =>{
    return(
        <Button
            htmlType="submit"
            loading={creating}
            type="primary"
            className={`w-72 relative mx-auto  justify-center py-5 flex items-center  ${!creating && 'deploy-button'}`}
        >
            <span className='font-semibold text-xl' >{label}</span>
            <svg className='absolute top-[14px] left-[190px] opacity-0 animateIcon' xmlns="http://www.w3.org/2000/svg" width="0.7em" height="1.2em" viewBox="0 0 320 512">
                <path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256L34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941"></path>
            </svg>
        </Button>
    )
}
 


